
import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { ArticleImageBlokInterface } from '~/types/articlePage';

export default defineComponent({
    name: 'ArticleImage',
    props: {
        blok: {
            type: Object as PropType<ArticleImageBlokInterface>,
            required: true,
        },
    },
});
