
import { defineComponent } from '@nuxtjs/composition-api';
import { getStoryblokImageDetails, ImageDetails } from '~/utils/storyblok';
import SpeedkitImage from '~/node_modules/nuxt-speedkit/runtime/components/SpeedkitImage/Base.vue';

interface CSSProperties {
    [key: string]: string | number;
}

export default defineComponent({
    name: 'StoryblokImage',
    components: { SpeedkitImage },
    inheritAttrs: false,
    props: {
        alt: {
            type: String,
            required: true,
        },
        src: {
            type: String,
            required: true,
        },
        sizes: {
            type: Object,
            required: true,
        },
        // This prop ensures rounded-circle styling on the image itself
        circular: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        imageDetails(): ImageDetails {
            return getStoryblokImageDetails(this.src);
        },
        imageContainerStyle() {
            const aspectRatio = (this.imageDetails.height / this.imageDetails.width) * 100;
            return {
                display: 'inline-block',
                position: 'relative',
                width: '100%',
                paddingBottom: `${aspectRatio}%`,
                overflow: 'hidden',
            } as CSSProperties;
        },
    },
});
